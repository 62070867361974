import ChakraCheckbox from 'common/components/Checkboxes/Checkbox/Checkbox';
import FormControl from 'common/componentMapper/FormControl';
import FormLabel from 'common/componentMapper/FormLabel';
import FormErrorMessage from 'common/componentMapper/FormErrorMessage';
import { useFieldApi } from '@data-driven-forms/react-form-renderer';
import { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { validationError } from '../utils';
import GenericTooltip from 'common/components/GenericTooltip';

const Checkbox = forwardRef((props, ref) => {
  const {
    input,
    label,
    helperText,
    required,
    isDisabled,
    readOnly,
    value,
    meta,
    validateOnMount,
    isInvalid,
    sx,
    checkboxSx,
    ...rest
  } = useFieldApi({
    ...props,
    type: 'checkbox',
  });
  const { name } = props;

  const invalid = validationError(meta, validateOnMount);
  const errorText =
    invalid ||
    ((meta.touched || validateOnMount) && meta.warning) ||
    helperText ||
    isInvalid ||
    meta.error;
  const showError = !(errorText === true || errorText === false);

  useEffect(() => {
    if (input && input.checked) {
      input.onChange(`${input.checked}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    if (!isDisabled && !readOnly) {
      input.onChange(event);
    }
  };

  return (
    <FormControl
      isInvalid={!!invalid && !isDisabled && showError}
      isRequired={required}
      isDisabled={isDisabled || readOnly}
      error={!!invalid}
      sx={sx}
      id={name}
      {...rest}
    >
      <FormLabel>
        <ChakraCheckbox
          value={value}
          {...input}
          isChecked={input.checked && !isDisabled && !readOnly}
          sx={checkboxSx}
          ref={ref}
          onChange={handleChange}
        >
          {label}
          {helperText && <GenericTooltip label={helperText} />}
        </ChakraCheckbox>
      </FormLabel>
      {showError && <FormErrorMessage>{errorText}</FormErrorMessage>}
    </FormControl>
  );
});

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  value: PropTypes.any,
  meta: PropTypes.object,
  validateOnMount: PropTypes.bool,
  isInvalid: PropTypes.bool,
  sx: PropTypes.object,
  checkboxSx: PropTypes.object,
};
Checkbox.displayName = 'CheckboxWithRef';

export default Checkbox;
