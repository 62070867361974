import { useEffect, useState, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import Viewer from './Viewer';
import YAMLOops from '../Oops/Oops';
import Spinner from 'common/components/Spinner';
import Box from 'common/components/Box';
import styled from '@emotion/styled';
import { useGetPDFUrl } from './useGetPDFUrl';
import { useDispatch } from 'react-redux';
import { setPdfDocumentId } from 'common/redux/actions';
import { logError, logWarning } from 'common/util/logger';

const StyledViewerContainer = styled(Box)`
  width: 100%;
  height: 100%;
  border-right: 1px solid ${(props) => props.theme.colors.gray[100]};
`;

/**
 * @param {Object} params - The parameters for the PDFViewer.
 * @param {string} params.defaultDocumentId - The ID of the default document to be displayed.
 * @param {boolean} [params.isFullPage=false] - Flag indicating if the viewer should be displayed in full page mode.
 * @param {{id:number;label:string;}[]} [params.documentOptions=[]] - An array of documents to show in the selection dropdown.
 *
 * Note: If you are building a YAML, `documentOptions` will be overridden by the value passed to the App Renderer via the `data` prop named `documentOptions`.
 *
 * @example // Do this if you are using YAML
 * const screenData = { documentOptions: [ { id: 1, label: "Document To Select"} ] }
 * <AppRenderer data={screenData} />
 */
function PDFViewer({
  defaultDocumentId,
  isFullPage = false,
  documentOptions = [],
}) {
  const [currentDocId, setCurrentDocId] = useState(defaultDocumentId);
  const [activeCitation, setActiveCitation] = useState(null);
  const dispatch = useDispatch();
  const isMounted = useRef(true);

  const { data: url, isLoading, isError } = useGetPDFUrl(currentDocId);

  const handlePDFCitationMessage = async (event) => {
    try {
      if (
        !(
          (event.data?.type === 'pdfCitation' && event.data.payload?.length) ||
          event.data.payload?.boundingBoxes?.length
        )
      )
        return;

      const citation = event.data.payload[0] || event.data.payload;
      if (isMounted.current) {
        setActiveCitation(citation);
      } else {
        logWarning('Attempted to set active citation on unmounted component');
      }

      const { documentId } = citation;
      if (documentId !== currentDocId && documentId) {
        if (isMounted.current) {
          setCurrentDocId(documentId);
        } else {
          logWarning(
            'Attempted to set current document ID on unmounted component',
          );
        }
      }
    } catch (error) {
      logError('Error handling PDF citation message:', error);
    }
  };

  useEffect(() => {
    dispatch(setPdfDocumentId(currentDocId));
  }, [currentDocId, dispatch]);

  useEffect(() => {
    isMounted.current = true;

    window.addEventListener('message', handlePDFCitationMessage);

    return () => {
      isMounted.current = false;
      window.removeEventListener('message', handlePDFCitationMessage, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasDocumentOrDefault = !!url || url === 'noDocumentFound';

  const isReady = !isError && !isLoading && hasDocumentOrDefault;

  return (
    <StyledViewerContainer>
      {isError && <YAMLOops />}
      {isLoading && <Spinner />}
      {isReady && (
        <Viewer
          docname={activeCitation?.documentType}
          pdf={url}
          highlights={[]}
          citations={activeCitation?.boundingBoxes}
          pageNumber={
            activeCitation?.boundingBoxes?.length
              ? activeCitation?.boundingBoxes[0]?.pageNumber
              : 0
          }
          pageRotations={null}
          isFullPage={isFullPage}
          documentOptions={documentOptions}
          showHighLight={activeCitation?.showHighlight}
        />
      )}
    </StyledViewerContainer>
  );
}

const arePropsEqual = (prevProps, nextProps) => {
  return (
    prevProps.defaultDocumentId === nextProps.defaultDocumentId &&
    prevProps.isFullPage === nextProps.isFullPage &&
    prevProps.documentOptions?.length === nextProps.documentOptions?.length
  );
};

const MemoizedPDFViewer = memo(PDFViewer, arePropsEqual);

export default MemoizedPDFViewer;

PDFViewer.propTypes = {
  defaultDocumentId: PropTypes.number,
  isFullPage: PropTypes.bool,
  documentOptions: PropTypes.array,
};
