/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StyledSection } from './Styled';
import { Box, Text } from 'common/components';
import { isReadOnlyUrl } from 'common/util/appState';

export const SelectedCategoryNavContainer = ({ children }) => {
  /**INFO: nav container for top selected categories  i.e Classify, Borrower, Liabilities, etc.*/
  const [loanSummary, setLoanSummary] = useState(null);
  useEffect(() => {
    // Retrieve loanSummary from sessionStorage
    const storedLoanSummary = sessionStorage.getItem('loanSummary');
    if (storedLoanSummary) {
      setLoanSummary(JSON.parse(storedLoanSummary));
    }
  }, []);

  const hasLoanSummaryData = !!loanSummary && !!loanSummary.value;

  return (
    <StyledSection isReadOnlyUrl={isReadOnlyUrl()}>
      {isReadOnlyUrl() && hasLoanSummaryData && (
        <Box ml="2.2rem">
          <Text fontWeight="500" color="gray.900" lineHeight="1.2rem">
            {loanSummary?.value?.loanNumber}
          </Text>
          <Text fontWeight="500" color="gray.900" lineHeight="1.2rem">
            {`${loanSummary?.value?.primaryBorrowerFirstName} ${loanSummary?.value?.primaryBorrowerLastName}`}
          </Text>
        </Box>
      )}

      <nav aria-label="selected category navigation">
        <ul
          style={{ display: 'flex', listStyleType: 'none' }}
          role="list"
          aria-label="category tablist"
        >
          {children}
        </ul>
      </nav>
    </StyledSection>
  );
};

SelectedCategoryNavContainer.propTypes = {
  children: PropTypes.node,
  loanSummary: PropTypes.object,
};

export default SelectedCategoryNavContainer;
