export const ASSETS_TYPES = {
  DELETE_ASSETS_STARTED: 'DELETE_ASSETS_STARTED',
  DELETE_ASSETS_SUCCESS: 'DELETE_ASSETS_SUCCESS',
  DELETE_ASSETS_ERROR: 'DELETE_ASSETS_ERROR',
  GET_ASSET_RECONCILIATION_INFORMATION_STARTED:
    'GET_ASSET_RECONCILIATION_INFORMATION_STARTED',
  GET_ASSET_RECONCILIATION_INFORMATION_SUCCESS:
    'GET_ASSET_RECONCILIATION_INFORMATION_SUCCESS',
  GET_ASSET_RECONCILIATION_INFORMATION_ERROR:
    'GET_ASSET_RECONCILIATION_INFORMATION_ERROR',
  SET_IS_DOCUMENT_PROVIDED: 'SET_IS_DOCUMENT_PROVIDED',
  SET_ASSET_CALCULATION_INFORMATION: 'SET_ASSET_CALCULATION_INFORMATION',
  RECONCILE_ASSETS_STARTED: 'RECONCILE_ASSETS_STARTED',
  RECONCILE_ASSETS_SUCCESS: 'RECONCILE_ASSETS_SUCCESS',

  SET_ASSET_TYPE: 'SET_ASSET_TYPE',
};

export default ASSETS_TYPES;
