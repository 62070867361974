import { HomeActions, HomeThunks } from 'pages/Home/redux';
import { CATEGORY_IDS } from 'pages/Home/constants';

export const signalRNavigationStatus = (
  response,
  waitForSignalR,
  selectedAlert,
) => {
  const selectedAlertTitle =
    selectedAlert === 'quickmatch'
      ? selectedAlert
      : selectedAlert?.displayTitle;
  if (response !== false && waitForSignalR) {
    return `Response from ${selectedAlertTitle} is good, Waiting for signalR to navigate to next alert.`;
  } else if (!waitForSignalR) {
    return `Value of waitForSignalR is ${waitForSignalR}, navigate to next alert without waiting for SignalR.`;
  } else {
    return `Response from the ${selectedAlertTitle} is ${response}, Wont navigate to next alert`;
  }
};

export const handlePrimaryButtonClick = async (
  event,
  onPrimaryButtonSubmit,
  dispatch,
  isUnderwriter,
  selectedAlert,
  waitForSignalR,
  selectedCategory,
  shouldAutoCheck,
  FEVerified,
) => {
  event.preventDefault();
  dispatch(HomeActions.setShouldGetNextAlert(true));
  const response = await onPrimaryButtonSubmit(event);
  // SignalR CONSOLE MESSAGE
  // eslint-disable-next-line no-console
  console.log(signalRNavigationStatus(response, waitForSignalR, selectedAlert));
  if (response !== false) {
    if (
      isUnderwriter &&
      selectedAlert &&
      selectedCategory !== CATEGORY_IDS.CLASSIFY &&
      selectedAlert.additionalReferences?.ExcludeUnderwriterVerification !==
        'True'
    ) {
      await dispatch(
        HomeThunks.saveUnderwriterVerification(
          shouldAutoCheck || FEVerified,
          selectedAlert,
        ),
      );
    }

    // Kafka + signalR round trip won't work on local,
    // so getNextAlert without waiting for signalR notification.
    // Also, If !waitForSignalR, getNextAlert without waiting.
    if (process.env.NODE_ENV === 'development' || !waitForSignalR) {
      dispatch(HomeActions.nextAlert());
      dispatch(HomeActions.setShouldGetNextAlert(false));
    }
    dispatch(HomeThunks.getLoanSummary());
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  } else {
    dispatch(HomeActions.setShouldGetNextAlert(false));
  }
};

export const handleSecondaryButtonClick = (event, onSecondaryButtonSubmit) => {
  event.preventDefault();
  onSecondaryButtonSubmit(event);
};
