import { useSelector } from 'react-redux';
import Spinner from 'common/components/Spinner';
import LoanSummaryDrawerAccordion from './components/LoanSummaryDrawerAccordion';
import Box from 'common/componentMapper/Box/Box';
import Text from 'common/componentMapper/Text/Text';
import Stack from 'common/componentMapper/Stack';
import styled from '@emotion/styled';
import { formatCurrency } from 'common/util/format';
import { dateFormatterForLoanSummary } from './handlers';
import language from 'common/constants/language.json';
import {
  AUS_METHOD_BY_NUMBER,
  BUSINESS_CHANNEL_BY_NUMBER,
  CLOSING_TYPE_BY_NUMBER,
  DOCUMENT_GENERATION_PROVIDER_BY_NUMBER,
  LOAN_PRODUCT_TYPE,
  LOAN_PURPOSE_TYPE_BY_NUMBER,
  OCCUPANCY_TYPE_BY_NUMBER,
  PROPERTY_REVIEW_LEVEL_BY_NUMBER,
  PROPERTY_TYPES_BY_NUMBER,
} from 'common/constants';
import useGetLoanSummaryData from './hooks/useGetLoanSummaryData';
import ErrorRetryMessage from './components/ErrorRetryMessage';

const LabelText = styled(Text)`
  font-weight: bold;
  padding-right: 0.25rem;
`;

const LoanSummary = () => {
  const { data, isLoading, isRefetching, isError, refetch } =
    useGetLoanSummaryData();

  const address = useSelector(
    ({ home }) => home?.loanSummary?.value?.propertyAddress?.wholeAddressString,
  );

  if (isLoading || isRefetching) {
    return <Spinner />;
  }

  if (isError) {
    return <ErrorRetryMessage refetch={refetch} />;
  }

  const borrower = data?.borrower;
  const loanInfo = data?.loanInfo;
  const loanSummaryDates = data?.loanSummaryDates;
  const miscellaneousInfo = data?.miscellaneousInfo;
  const propertyData = data?.propertyData;
  const isFHA = loanInfo?.loanProductType === LOAN_PRODUCT_TYPE.FHA;

  /**INFO: For Box component pseudo css selectors don't work for styled-components */
  return (
    <Box
      id="loan-summary"
      aria-label="loan summary"
      sx={{
        padding: '2rem .5rem',
        maxHeight: '100vh',
        scrollBehavior: 'smooth',
        overflowY: 'scroll',
        '::-webkit-scrollbar': {
          width: '6px',
          backgroundColor: 'transparent',
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: '#BABAC0',
          width: '4px',
          border: '4px solid #BABAC0',
        },
        '::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <Text
        sx={{ marginBottom: '1rem', fontWeight: 'bold' }}
        text="Loan Summary"
      />
      <LoanSummaryDrawerAccordion header="Borrower" id="borrower-accordion">
        <Stack direction="row">
          <LabelText text="Name:" />
          <Text text={`${borrower?.firstName} ${borrower?.lastName}`} />
        </Stack>
        <Stack direction="row">
          <LabelText text="SSN:" />
          <Text text={`${borrower?.ssNumber ?? ''}`} />
        </Stack>
      </LoanSummaryDrawerAccordion>
      <LoanSummaryDrawerAccordion header="Property" id="property-accordion">
        <Stack direction="column" sx={{ wordWrap: 'normal' }}>
          <LabelText text="Address:" />
          <Text sx={{ marginTop: '0 !important' }} text={address ?? ''} />
        </Stack>
        <Stack direction="column" sx={{ wordWrap: 'normal' }}>
          <LabelText text="Mortgage Purpose:" />
          <Text
            sx={{ marginTop: '0 !important' }}
            text={LOAN_PURPOSE_TYPE_BY_NUMBER[propertyData?.purposeType] ?? ''}
          />
        </Stack>
        <Stack direction="column">
          <LabelText text="Occupancy:" />
          <Text
            sx={{ marginTop: '0 !important' }}
            text={OCCUPANCY_TYPE_BY_NUMBER[propertyData?.occupancyType] ?? ''}
          />
        </Stack>
        <Stack direction="column" sx={{ wordWrap: 'normal' }}>
          <LabelText text="Property Type:" />
          <Text
            sx={{ marginTop: '0 !important' }}
            text={PROPERTY_TYPES_BY_NUMBER[propertyData?.propertyType] ?? ''}
          />
        </Stack>
        <Stack direction="row">
          <LabelText text="Attached/Detached:" />
          <Text
            text={propertyData?.isAttachedProperty ? 'Attached' : 'Detached'}
          />
        </Stack>
      </LoanSummaryDrawerAccordion>
      <LoanSummaryDrawerAccordion
        header="Loan Details"
        id="loan-details-accordion"
      >
        <Stack direction="row">
          <LabelText text="Loan Number:" />
          <Text text={loanInfo?.loanNumber ?? ''} />
        </Stack>
        <Stack direction="row">
          <LabelText text="Loan Amount/Total:" />
          <Text text={formatCurrency(loanInfo?.totalLoanAmount) ?? ''} />
        </Stack>
        <Stack direction="row">
          <LabelText text="Sales Price/Purchase:" />
          <Text text={formatCurrency(loanInfo?.salesPrice) ?? ''} />
        </Stack>
        <Stack direction="row">
          <LabelText text="Appraised Value:" />
          <Text text={formatCurrency(loanInfo?.appraisedValue) ?? ''} />
        </Stack>
        <Stack direction="row">
          <LabelText text="HTI/DTI:" />
          <Text text={`${loanInfo?.hti}/${loanInfo?.dti}`} />
        </Stack>
        <Stack direction="column">
          <LabelText text="LTV/CLTV/HCLTV:" />
          <Text
            sx={{ marginTop: '0 !important' }}
            text={`${loanInfo?.ltv}/${loanInfo?.cltv}/${loanInfo?.hcltv}`}
          />
        </Stack>
        <Stack direction="row">
          <LabelText text="FICO Source:" />
          <Text text={loanInfo?.fico ?? ''} />
        </Stack>
        <Stack direction="row">
          <LabelText text="AUS:" />
          <Text
            text={AUS_METHOD_BY_NUMBER[loanInfo?.underwritingMethod] ?? ''}
          />
        </Stack>
        <Stack direction="row">
          <LabelText text="Interest Rate:" />
          <Text text={`${loanInfo?.interestRate}%` ?? ''} />
        </Stack>
        {isFHA && (
          <Stack direction="row">
            <LabelText text="FHA Case Number:" />
            <Text text={loanInfo?.agencyCaseNumber ?? language.NOT_AVAILABLE} />
          </Stack>
        )}
      </LoanSummaryDrawerAccordion>
      <LoanSummaryDrawerAccordion header="Dates" id="dates-accordion">
        <Stack direction="row">
          <LabelText text="1003 Signature Date:" />
          <Text
            text={dateFormatterForLoanSummary(
              loanSummaryDates?.loanApplicationDate,
            )}
          />
        </Stack>
        <Stack direction="row">
          <LabelText text="Approval Expiration:" />
          <Text
            text={dateFormatterForLoanSummary(
              loanSummaryDates?.approvalExpirationDate,
            )}
          />
        </Stack>
        <Stack direction="row">
          <LabelText text="Loan Expiration Date:" />
          <Text
            text={dateFormatterForLoanSummary(
              loanSummaryDates?.lockExpirationDate,
            )}
          />
        </Stack>
        <Stack direction="row">
          <LabelText text="Closing Date:" />
          <Text
            text={dateFormatterForLoanSummary(
              loanSummaryDates?.actualClosingDate,
            )}
          />
        </Stack>
        <Stack direction="row">
          <LabelText text="Disbursement Date:" />
          <Text
            text={dateFormatterForLoanSummary(
              loanSummaryDates?.disbursementDate,
            )}
          />
        </Stack>
        <Stack direction="row">
          <LabelText text="First Payment Date:" />
          <Text
            text={dateFormatterForLoanSummary(
              loanSummaryDates?.firstPaymentDate,
            )}
          />
        </Stack>
        <Stack direction="row">
          <LabelText text="Maturity Date:" />
          <Text
            text={dateFormatterForLoanSummary(loanSummaryDates?.maturityDate)}
          />
        </Stack>
      </LoanSummaryDrawerAccordion>
      <LoanSummaryDrawerAccordion header="Miscellaneous" id="misc-accordion">
        <Stack direction="row">
          <LabelText text="Closing Type:" />
          <Text
            text={CLOSING_TYPE_BY_NUMBER[miscellaneousInfo?.closingType] ?? ''}
          />
        </Stack>
        <Stack direction="row">
          <LabelText text="Business Channel:" />
          <Text
            text={
              BUSINESS_CHANNEL_BY_NUMBER[miscellaneousInfo?.businessChannel] ??
              ''
            }
          />
        </Stack>
        <Stack direction="row">
          <LabelText text="Document Provider:" />
          <Text
            text={
              DOCUMENT_GENERATION_PROVIDER_BY_NUMBER[
                miscellaneousInfo?.documentGenerationProvider
              ] ?? ''
            }
          />
        </Stack>
        <Stack direction="row">
          <LabelText text="URLA Version:" />
          <Text text={miscellaneousInfo?.urlaVersion ?? ''} />
        </Stack>
        <Stack direction="row">
          <LabelText text="Assigned AMC:" />
          <Text text={miscellaneousInfo?.amcName ?? ''} />
        </Stack>
        <Stack direction="row">
          <LabelText text="PITI:" />
          <Text text={formatCurrency(miscellaneousInfo?.piti) ?? ''} />
        </Stack>
        <Stack direction="row">
          <LabelText text="Property Review Level:" />
          <Text
            text={
              PROPERTY_REVIEW_LEVEL_BY_NUMBER[
                miscellaneousInfo?.propertyReviewLevel
              ] ?? ''
            }
          />
        </Stack>
      </LoanSummaryDrawerAccordion>
    </Box>
  );
};

export default LoanSummary;
