import Alert from './Alert/Alert';
import Accordion from './Accordion/Accordion';
import Button from './Button/Button';
import ToggleButton from './ToggleButton';
import Box from './Box/Box';
import Form from './Form/Form';
import Grid from './Grid/Grid';
import Stack from './Stack';
import Input from './Input/Input';
import RadioButton from './RadioButton';
import Datalist from './Datalist';
import Modal from './Modal/Modal';
import Select from './Select/Select';
import MultiSelect from './Select/MultiSelect';
import { Checkbox, CheckboxGroup } from './Checkbox';
import { Radio } from './Radio';
import { Page } from './Page';
import Text from './Text/Text';
import ChakraTable from './ChakraTable';
import ChakraTableRow from './ChakraTable/TableRow';
import Table, { TableRow } from './Table';
import DynamicTable from './ChakraTable/DTable';
import DownloadLink from './DownloadLink/DownloadLink';
import CreditReportLink from './CreditReportLink/CreditReportLink';
import BreakdownSummation from './BreakdownSummation';
import CommentBox from './CommentBox';
import FieldArrayCustom from './FieldArray/FieldArrayCustom';
import Icon from './Icon/Icon';
import KYLTableRow from './ChakraTable/KYLTableRow';
import KYLTable from './ChakraTable/KYLTable';
import QuestionExtraction from './QuestionExtraction';
import QuestionBox from './QuestionBox/QuestionBox';
import PDFViewer from './PdfViewer';
import EffectiveDateAlert from './Alert/EffectiveDateAlert';
import YAMLOops from './Oops/Oops';
import FullPageWithPDFViewer from './FullPageWithPDFViewer/FullPageWithPDFViewer';
import Divider from './Divider/Divider';
import ActiveFieldBackground from './ActiveFieldBackground/ActiveFieldBackground';
import HiddenElement from './HiddenElement/HiddenElement';
import ExtractionStatusIcon from './Icon/ExtractionStatusIcon';
import CustomSelectWithStyledOptions from './Select/CustomSelectWithStyledOptions';
import AssetsAmountMismatchAlert from './Alert/AssetsAmountMismatchAlert';
import ExtractionDataIcon from './QuestionExtraction/components/ExtractionDataIcon';
import CalculationHistory from './CalculationHistory/CalculationHistory';

const componentMapper = {
  Alert: Alert,
  Accordion: Accordion,
  Radio: Radio,
  Input: Input,
  RadioButton,
  Datalist,
  Dialog: Modal,
  DownloadLink: DownloadLink,
  Button: Button,
  ToggleButton,
  BreakdownSummation: BreakdownSummation,
  Checkbox: Checkbox,
  Box: Box,
  Stack,
  Form: Form,
  Grid: Grid,
  Select: Select,
  MultiSelect: MultiSelect,
  Page: Page,
  Text: Text,
  CreditReportLink: CreditReportLink,
  DynamicTable: DynamicTable,
  KYLTableRow: KYLTableRow,
  KYLTable: KYLTable,
  ChakraTable: ChakraTable,
  ChakraTableRow: ChakraTableRow,
  Table,
  TableRow,
  CommentBox: CommentBox,
  CheckboxGroup: CheckboxGroup,
  FieldArrayCustom: FieldArrayCustom,
  Icon: Icon,
  ExtractionDataIcon: ExtractionDataIcon,
  QuestionBox: QuestionBox,
  QuestionExtraction: QuestionExtraction,
  FullPageWithPDFViewer: FullPageWithPDFViewer,
  PDFViewer: PDFViewer,
  EffectiveDateAlert: EffectiveDateAlert,
  AssetsAmountMismatchAlert: AssetsAmountMismatchAlert,
  YAMLOops: YAMLOops,
  Divider: Divider,
  ActiveFieldBackground: ActiveFieldBackground,
  HiddenElement: HiddenElement, //INFO: will be removed after Title Refi pilot
  ExtractionStatusIcon,
  CustomSelectWithStyledOptions: CustomSelectWithStyledOptions,
  CalculationHistory: CalculationHistory,
};
export { FormTemplateWithAuditHistory } from './FormTemplate';
export { FormTemplate } from './FormTemplate';
export { NonGlobalLayoutFormTemplate } from './FormTemplate';
export default componentMapper;
