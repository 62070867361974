import { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';

import Header from './Header';
import Spinner from '../Spinner';
import Error from './Error';
import Content from './Content';
import Footer from './Footer';
import { useToast } from '@chakra-ui/react';
import Box from 'common/components/Box';

import { AuditHistoryLayoutHeader } from '../AuditHistoryButton';
import propTypes from 'common/propTypes';

// local imports
import { HomeActions, HomeThunks } from 'pages/Home/redux';
import { signalRNavigationStatus } from './signalRNavigation';
import { USER_ROLE } from 'common/constants';
import lang from 'common/constants/language';
import useFullPageWithPDFViewer from 'common/hooks/useFullPageWithPDFViewer';
import { BusinessAssetTypeArray } from 'pages/Assets/AssetDepositoryAccounts/enum';
import { determineAutoCheck } from 'common/util/underwriter';

const StyledMain = styled.main`
  // default styles
  box-sizing: border-box;
  //TODO: remove isFullPageWithPDFViewer once PDFViewer layout and LeftDrawer collapse design is solidified
  padding: ${(props) =>
    props.isFullPageWithPDFViewer ? '0' : '24px 32px 24px 32px'};
  height: ${(props) => (props.isFullPageWithPDFViewer ? 'auto' : '100%')};
  /* 
  additional styles(or styles to override default)
  ALWAYS keep this at the bottom 
  */
  ${(props) => props?.customStyles?.main}
`;

const GlobalLayout = ({
  as = 'div',
  handleSubmit,
  handleNextButton,
  children,
  customHeader = null,
  error,
  heading,
  isLoading,
  loadingMessage,
  primaryButton,
  primaryButtonId,
  secondaryButton,
  subHeading,
  customStyles,
  isDirty,
  subHeadingStyles,
  waitForSignalR,
  auditHistoryData = null,
  shouldAutoCheck = false,
  globalLayoutName = null,
}) => {
  const {
    user: { isUnderwriter, role },
    boltStatus,
    navigationItems,
    selectedAlert,
    selectedCategory,
    isToastOpen,
    isZappedLoan,
    toastMessage,
  } = useSelector((state) => state.home);
  const { FEVerified } = useSelector((state) => state.feVerified);
  const isDocumentProvided = useSelector(
    (state) => state?.assets?.isDocumentProvided,
  );
  const assetType = useSelector((state) => state?.assets?.assetType);
  const dispatch = useDispatch();
  const toast = useToast();

  const shouldAutoCheckOrZap = determineAutoCheck(
    isZappedLoan,
    shouldAutoCheck,
    selectedAlert?.ruleId,
  );

  //TODO: remove once GlobalLayout has been refactored and SDUI has been implemented for selected page
  const { isFullPageWithPDFViewer } = useFullPageWithPDFViewer();

  const isReadOnlyPermission = (
    navigationItems,
    selectedAlert,
    role,
    isUnderwriter,
    boltStatus,
    assetType,
    isDocumentProvided,
  ) => {
    const isCategoryReadOnlyPermission = navigationItems?.categories?.find(
      (category) => category?.navigationCategoryId === selectedCategory,
    )?.isReadOnlyPermission;

    let iscategoryGutterItemReadOnlyPermission;

    if (BusinessAssetTypeArray.includes(assetType)) {
      if (selectedAlert?.isReadOnlyPermission && !isDocumentProvided) {
        return (iscategoryGutterItemReadOnlyPermission = false);
      }

      return (iscategoryGutterItemReadOnlyPermission =
        selectedAlert?.isReadOnlyPermission);
    }

    return (
      (!isUnderwriter && boltStatus?.value) ||
      role === USER_ROLE.ACCOUNT_EXECUTIVE ||
      isCategoryReadOnlyPermission ||
      iscategoryGutterItemReadOnlyPermission
    );
  };

  const isWriteAccessDisabled = isReadOnlyPermission(
    navigationItems,
    selectedAlert,
    role,
    isUnderwriter,
    boltStatus,
    assetType,
    isDocumentProvided,
  );

  // TODO: we should look to use the fn from defined in src/common/components/GlobalLayout/signalRNavigation.js
  const handlePrimaryButtonClick = (
    isUnderwriter,
    shouldAutoCheck,
    selectedAlert,
    waitForSignalR = true,
    FEVerified,
  ) => {
    dispatch(HomeActions.setShouldGetNextAlert(true));

    const navigationStatus = signalRNavigationStatus(
      true, // response is truthy as you wont get here if form is not validated
      waitForSignalR,
      selectedAlert,
    );

    // Logging the SingalR status in console!
    // eslint-disable-next-line no-console
    console.log(navigationStatus);
    if (isUnderwriter && selectedAlert) {
      dispatch(
        HomeThunks.saveUnderwriterVerification(shouldAutoCheck || FEVerified),
      );
    }

    // Kafka + signalR round trip won't work on local,
    // so getNextAlert without waiting for signalR notification.
    // Also, If !waitForSignalR, getNextAlert without waiting.
    if (process.env.NODE_ENV === 'development' || !waitForSignalR) {
      dispatch(HomeActions.nextAlert());
      dispatch(HomeActions.setShouldGetNextAlert(false));
    }
    dispatch(HomeThunks.getLoanSummary());
  };

  useEffect(() => {
    const onBeforeUnload = (event) => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => window.removeEventListener('beforeunload', onBeforeUnload);
  }, [isDirty]);

  useEffect(() => {
    if (isToastOpen && toastMessage) {
      toast({
        id: toastMessage.id,
        title: toastMessage.title || lang.DEFAULT_OOPS_TITLE,
        status: toastMessage.status || lang.TOAST_ERROR_STATUS,
        description: toastMessage.description,
        duration: toastMessage.duration || 5000,
        position: toastMessage.position || 'bottom',
        isClosable:
          toastMessage.isClosable !== undefined
            ? toastMessage.isClosable
            : true,
        onCloseComplete: () => {
          toast.closeAll();
          // This is needed to reset the toast open status
          dispatch(HomeActions.setIsToastOpen(false, {}));
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isToastOpen]);

  async function submitHandler(data) {
    const response = await handleNextButton(data);

    response &&
      handlePrimaryButtonClick(
        isUnderwriter,
        shouldAutoCheckOrZap,
        selectedAlert,
        waitForSignalR,
        FEVerified,
      );
  }

  return (
    <Box
      as={as}
      onSubmit={handleSubmit && handleSubmit(submitHandler)}
      data-testid={globalLayoutName ? globalLayoutName : `globalLayout-${as}`}
      borderTopRightRadius="6px"
      borderBottomRightRadius="6px"
      backgroundColor="white"
      display="flex"
      flexDirection="column"
      height="100%"
    >
      {/* show Audit History if props else use customHeader or default */}
      {auditHistoryData ? (
        <AuditHistoryLayoutHeader
          {...auditHistoryData}
          isWriteAccessDisabled={isWriteAccessDisabled}
        />
      ) : (
        customHeader ?? (
          <Header
            heading={heading}
            isWriteAccessDisabled={isWriteAccessDisabled}
          />
        )
      )}
      {isLoading && !error ? (
        <Spinner loadingMessage={loadingMessage} />
      ) : (
        <StyledMain
          customStyles={customStyles}
          className="content-body"
          isFullPageWithPDFViewer={isFullPageWithPDFViewer}
        >
          {error ? (
            <Error error={error} />
          ) : (
            <Content
              subHeading={subHeading}
              subHeadingStyles={subHeadingStyles}
              isWriteAccessDisabled={isWriteAccessDisabled}
            >
              {children}
            </Content>
          )}
        </StyledMain>
      )}

      {(primaryButton || secondaryButton) && (
        <Footer
          isInForm={as === 'form'}
          shouldAutoCheck={shouldAutoCheckOrZap}
          primaryButton={
            primaryButton && {
              id: primaryButtonId,
              ...primaryButton,
              isDisabled: isWriteAccessDisabled || primaryButton.isDisabled,
            }
          }
          secondaryButton={
            secondaryButton && {
              ...secondaryButton,
              isDisabled: secondaryButton.isDisabled,
            }
          }
          error={error}
          waitForSignalR={waitForSignalR}
        />
      )}
    </Box>
  );
};

GlobalLayout.propTypes = {
  children: PropTypes.any,
  as: PropTypes.string,
  handleSubmit: (props, propName) => {
    if (props['as'] === 'form' && !props[propName]) {
      // eslint-disable-next-line no-console
      console.error('Please provide a handleSubmit function!');
    }
  },
  handleNextButton: PropTypes.func,
  customHeader: PropTypes.node,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  heading: PropTypes.string,
  isLoading: PropTypes.bool,
  loadingMessage: PropTypes.string,
  primaryButtonId: PropTypes.string,
  primaryButton: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    onSubmit: PropTypes.func,
    isDisabled: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    'data-testid': PropTypes.string,
  }),
  secondaryButton: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string,
      onSubmit: PropTypes.func,
      isDisabled: PropTypes.bool,
      isSubmitting: PropTypes.bool,
      'data-testid': PropTypes.string,
    }),
    PropTypes.bool,
  ]),
  subHeading: PropTypes.string,
  customStyles: PropTypes.shape({
    main: PropTypes.string,
  }),
  isDirty: PropTypes.bool,
  subHeadingStyles: PropTypes.object,
  waitForSignalR: PropTypes.bool,
  auditHistoryData: propTypes.auditHistoryData,
  shouldAutoCheck: PropTypes.bool,
  globalLayoutName: PropTypes.string,
};

export default GlobalLayout;
