// 3rd party imports
import { useSelector, useDispatch } from 'react-redux';
import Flex from 'common/components/Flex';
import { compact } from 'lodash';
import { useMemo } from 'react';

// local imports
import Notification from './Notification';
import { HomeActions } from './redux';
import { RULE_RESULT_STATUS_ID } from 'common/constants';
import { CATEGORY_DETAILS, CATEGORY_IDS } from './constants';
import { NavItem } from 'common/components/Nav/components/Styled';
import SelectedCategoryNavContainer from 'common/components/Nav/components/SelectedCategoryNavContainer';

const CategoriesNav = () => {
  // redux
  const dispatch = useDispatch();
  const { home } = useSelector((state) => state);
  const selectedCategory = home?.selectedCategory;
  const allCategories = useMemo(
    () => home?.navigationItems?.categories,
    [home],
  );

  // event handlers
  const handleCategoryClick = (categoryId) => {
    if (categoryId !== CATEGORY_IDS.UNDERWRITER_FINAL_REVIEW) {
      dispatch(HomeActions.setIsUnderwriterCheckboxDisabled(false));
    }
    // return the match categoryId key name from CATEGORY_IDS
    const categoryNavName = Object.keys(CATEGORY_IDS).find(
      (key) => CATEGORY_IDS[key] === categoryId,
    );

    dispatch(HomeActions.setSelectedAlert(null));
    dispatch(HomeActions.setClickedSelectedCategory(categoryNavName));
    dispatch(HomeActions.setSelectedCategory(categoryId));
  };

  // TODO: improve perf
  // helper functions
  const getNumberOfActiveAlerts = (categoryId) => {
    return compact(
      allCategories
        ?.find((category) => category?.navigationCategoryId === categoryId)
        ?.navigationGutterItems?.flatMap((navigationGutterItem) => {
          if (navigationGutterItem?.gutterItemChildren?.length > 0) {
            return navigationGutterItem?.gutterItemChildren.filter(
              (childItem) =>
                childItem?.ruleResultStatusId === RULE_RESULT_STATUS_ID.FIRED,
            );
          } else if (
            navigationGutterItem?.ruleResultStatusId ===
            RULE_RESULT_STATUS_ID.FIRED
          ) {
            return navigationGutterItem;
          }
          return null;
        }),
    )?.length;
  };

  const isCategoryVerified = (category) => {
    // If there are no gutter items, then the category can't be verified
    if (category?.navigationGutterItems?.length === 0) return false;

    // If every gutter item is verified, then the category is verified
    const isVerified =
      category?.navigationGutterItems?.every(isGutterItemVerified) ?? false;

    return isVerified;
  };

  const isGutterItemVerified = (gutterItem) => {
    // If there are no children for this item
    if (gutterItem?.gutterItemChildren?.length == 0)
      // If the item has a ruleResultId of 0, then this is a shadow rule, and we should just say that it's been verified
      //   otherwise, return the value of if the Underwriter has verified this item.
      return (
        gutterItem?.ruleResultId === 0 ||
        (gutterItem?.isUnderwriterVerified ?? false)
      );

    // Recursively call for every child to see if they are valid as well.
    return gutterItem?.gutterItemChildren?.every(isGutterItemVerified);
  };

  return (
    <SelectedCategoryNavContainer>
      {CATEGORY_DETAILS.map((category) => {
        const currentCategory = allCategories?.find(
          (item) => item?.navigationCategoryId === category.id,
        );
        return (
          currentCategory && (
            <NavItem
              onClick={() => handleCategoryClick(category.id)}
              selected={selectedCategory === category.id}
              key={category.id}
              role="tab"
              data-testid={category.name}
              data-tracking={category.name}
              aria-selected={selectedCategory === category.id}
            >
              <Flex>
                <Notification
                  currentCategoryId={category.id}
                  numberOfActiveAlerts={getNumberOfActiveAlerts(category.id)}
                  isVerified={isCategoryVerified(currentCategory)}
                />
                <span>{category.name}</span>
              </Flex>
            </NavItem>
          )
        );
      })}
    </SelectedCategoryNavContainer>
  );
};

export default CategoriesNav;
