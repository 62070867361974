/**
 * Logs an error message and error object to the console
 * @param {string} message The message to log
 * @param {Error} error The error to log
 * @returns {void}
 * @example
 * logError('Error handling PDF citation message:', error);
 * @example
 * logWarning('Attempted to set active citation on unmounted component');
 */

const logError = (message, error) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error(message, error);
  }
  // Additional logic for logging errors in production can be added here
};

const logWarning = (message) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.warn(message);
  }
  // Additional logic for logging warnings in production can be added here
};

export { logError, logWarning };
