import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'common/components/Button';
import { Tbody, Thead, Tr, Td } from 'common/components/Tables';
import Box from 'common/components/Box';
import Text from 'common/components/Texts/Text';
import Flex from 'common/components/Flex';
import Checkbox from 'common/components/Checkboxes/Checkbox/Checkbox';
import {
  faTrash,
  faArrowUpArrowDown,
  faArrowDownShortWide,
  faArrowUpWideShort,
} from '@fortawesome/pro-regular-svg-icons';
import ConfirmationDialog from 'common/components/ConfirmationDialog';
import Spinner from 'common/components/Spinner';
import { handleSettingColumnSort } from './crudGridUtils';
import theme from 'theme/';
import { SortIcon } from './SortIcon';
import CrudGridToolbar from './CrudGridToolbar';
import {
  StyledTableHead,
  StyledTable,
  StyledTr,
  StyledDeleteMultipleBtnGroup,
  StyledFontAwesomeIcon,
} from './GridStyledComponents';
import filterGridData from './Filters/filterGridData';
import { isReadOnlyUrl } from 'common/util/appState';

/**
 * A reusable CRUD grid component for displaying and managing data.
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.data - An array of objects representing the data to be displayed in the grid.
 * @param {Object} props.selectedRow - The object representing the currently selected row.
 * @param {Object} props.gridDef - The grid definition object containing configuration options for the grid.
 * @param {Array} props.columns - An array of column objects defining the columns to be displayed in the grid.
 * @param {Function} props.onRowEditClick - The function to be called when a row is clicked for editing.
 * @param {Function} props.onDelete - The function to be called when the delete button is clicked.
 * @param {boolean} props.showDeleteMultiple - A boolean indicating whether the delete multiple button should be displayed.
 * @param {Function} props.setShowDeleteMultiple - The function to be called to set the showDeleteMultiple.
 * @param {boolean} props.isLoading - A boolean indicating whether the grid is in a loading state.
 * @returns {JSX.Element} The rendered CRUD grid component.
 */

export default function CrudGrid({
  gridDef,
  columns,
  data,
  onRowEditClick,
  selectedRow,
  onDelete,
  isLoading = true,
  dropdownOptions,
  readOnly,
  filters,
  multiSelectActionDef,
  buttonDefs,
  onGridModeChange,
  children,
  disableRowClick = false,
  isAuditHistoryEnabled,
}) {
  const {
    gridType,
    defaultSort,
    uniqueIdAttributeFields,
    getDialogDeleteMessage,
    manageColumns,
    gridLanguage,
    allowFilters,
  } = gridDef;
  const [gridColumns, setGridColumns] = useState([...columns]);
  const [gridDisplayData, setGridDisplayData] = useState(data);
  const [showDeleteMultiple, setShowDeleteMultiple] = useState(false);
  const [gridSort, setGridSort] = useState(
    defaultSort
      ? {
          ...defaultSort,
          icon:
            defaultSort.direction === 'desc'
              ? faArrowUpWideShort
              : faArrowDownShortWide,
        }
      : { field: '', direction: '' },
  );
  const [appliedFilters, setAppliedFilters] = useState([]);

  const [checkedItems, setCheckedItems] = useState([]);
  const [selectDeselectAll, setSelectDeselectAll] = useState(false);

  useEffect(() => {
    setGridDisplayData(filterGridData([...data], appliedFilters, gridSort));
  }, [data, appliedFilters, gridSort]);

  useEffect(() => {
    setShowDeleteMultiple(false);
  }, [data]);

  const getUniqueId = (row) => {
    let id = '';
    uniqueIdAttributeFields.forEach((field) => {
      const fieldPath = field.split('.');
      const value = fieldPath.reduce(
        (obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : ''),
        row,
      );
      id += value;
    });
    return id;
  };

  const handleRowClick = useCallback((row, e) => {
    if (!e.target.closest('.crud-grid-action-cell') && !showDeleteMultiple) {
      onRowEditClick(row);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Sets the gridSort state variable that controls how the grid is currently sorted.
   * @function handleColumnSortClick
   * @param {Object} column - The column object.
   * @param {string} column.fieldName - The field name of the column.
   * @param {string} column.headerName - The header display name of the column.
   */
  const handleColumnSortClick = (column) => {
    setGridSort(
      handleSettingColumnSort(
        column,
        gridSort,
        faArrowUpArrowDown,
        faArrowDownShortWide,
        faArrowUpWideShort,
      ),
    );
  };

  const handleToggleColumn = (i) => {
    const newColumns = [...gridColumns];
    newColumns[i].display = !gridColumns[i].display;
    setGridColumns(newColumns);
  };

  const isSelectedRow = (id) => {
    const foundId = checkedItems.find((item) => getUniqueId(item) === id);
    return foundId !== undefined;
  };

  useEffect(() => {
    // When the Delete Multiple session is cancelled, all checkboxes should be unchecked
    if (!showDeleteMultiple) {
      setSelectDeselectAll(!selectDeselectAll);
      setCheckedItems([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDeleteMultiple]);

  return (
    <Box
      className="crudGrid"
      w="100%"
      d="flex"
      justifyContent="flex-start"
      flexDirection="column"
    >
      {gridLanguage?.helperText && (
        <Text fontWeight={'bold'} alignSelf={'flex-start'} marginBottom="1em">
          {gridLanguage.helperText}
        </Text>
      )}
      {(manageColumns || allowFilters || multiSelectActionDef) &&
        !isReadOnlyUrl() && (
          <CrudGridToolbar
            columns={gridColumns}
            gridType={gridType}
            toggleColumn={handleToggleColumn}
            resetColumns={() => setGridColumns([...columns])}
            manageColumns={manageColumns ?? false}
            allowFilters={allowFilters ?? false}
            isReadOnly={readOnly}
            multiSelectAction={multiSelectActionDef}
            isMultiSelectActionMode={showDeleteMultiple}
            filters={filters}
            appliedFilters={appliedFilters}
            onFilterChange={(filters) => setAppliedFilters(filters)}
            onActionClick={() => {
              onGridModeChange();
              setShowDeleteMultiple(!showDeleteMultiple);
            }}
            onSubActionClick={(action) => {
              setCheckedItems((prev) => action.action(gridDisplayData, prev));
            }}
            isAuditHistoryEnabled={isAuditHistoryEnabled}
          />
        )}

      <StyledTable size="sm" colorScheme="gray" w="100%">
        <Thead
          style={{
            backgroundColor: isReadOnlyUrl()
              ? theme.colors?.bgGray[300]
              : theme.colors?.blue[600],
            zIndex: 1,
          }}
        >
          <Tr>
            {gridColumns.map((column, i) => {
              if (column.display) {
                return (
                  <StyledTableHead
                    key={i}
                    scope="col"
                    onClick={() => handleColumnSortClick(column)}
                    isReadOnlyUrl={isReadOnlyUrl()}
                  >
                    <Flex justify={'space-between'} align={'center'}>
                      <Text>{column.headerName}</Text>
                      <SortIcon
                        fieldName={column.fieldName}
                        sortable={column.sortable}
                        gridSort={gridSort}
                        isReadOnlyUrl={isReadOnlyUrl()}
                      />
                    </Flex>
                  </StyledTableHead>
                );
              }
            })}
            {!readOnly && (
              <StyledTableHead
                textAlign={'start'}
                scope="col"
                isReadOnlyUrl={isReadOnlyUrl()}
              >
                Delete
              </StyledTableHead>
            )}
          </Tr>
        </Thead>
        <Tbody>
          {gridDisplayData.length === 0 && !isLoading && (
            <Tr>
              <Td colSpan={gridColumns.length + 1} textAlign={'center'}>
                No Data to Display
              </Td>
            </Tr>
          )}
          {isLoading && gridDisplayData.length === 0 && (
            <Tr>
              <Td colSpan={gridColumns.length + 1} textAlign={'center'}>
                <Spinner backgroundColor={'none'} height={'20rem'} />
              </Td>
            </Tr>
          )}
          {gridDisplayData.map((row, i) => (
            <StyledTr
              key={i}
              disableRowClick={disableRowClick}
              background={
                getUniqueId(row) === getUniqueId(selectedRow)
                  ? theme?.colors?.blue[50]
                  : i % 2 === 0
                  ? theme?.colors?.bgGray[100]
                  : ''
              }
              data-testid={`crud-grid-row-${i}`}
              onClick={
                disableRowClick ? undefined : (e) => handleRowClick(row, e)
              }
            >
              {gridColumns.map((column, i) => {
                if (column.display) {
                  return (
                    <Td
                      key={i}
                      borderRight={`1px solid ${theme?.colors?.gray[200]}`}
                      align={column.isNumeric ? 'right' : 'left'}
                      lineHeight="1.75"
                    >
                      {column.renderCell
                        ? column.renderCell(
                            row[column.fieldName],
                            column.useOptions ? dropdownOptions : '',
                          )
                        : row[column.fieldName]}
                    </Td>
                  );
                }
              })}
              {!readOnly && (
                <Td
                  borderRight={`1px solid ${theme?.colors?.gray[200]}`}
                  textAlign={'center'}
                  className="crud-grid-action-cell"
                >
                  {!showDeleteMultiple ? (
                    <ConfirmationDialog
                      message={getDialogDeleteMessage([row])}
                      itemName={gridLanguage.singularItem}
                      isDeleteDisabled={
                        row.isSubjectProperty &&
                        gridLanguage.singularItem === 'REO'
                      }
                      buttonId={`delete-button-${i}`}
                      onClick={() => onDelete([row])}
                    >
                      <StyledFontAwesomeIcon
                        icon={faTrash}
                        data-action="delete"
                        data-testid={`delete-grid-icon-button-${i}`}
                      />
                    </ConfirmationDialog>
                  ) : (
                    <Checkbox
                      id={`checkboxDelete-${i}`}
                      data-testid={`checkboxDelete-${i}`}
                      isChecked={isSelectedRow(getUniqueId(row))}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setCheckedItems((prev) => [...prev, row]);
                        } else {
                          setCheckedItems(
                            checkedItems.filter(
                              (item) => getUniqueId(item) !== getUniqueId(row),
                            ),
                          );
                        }
                      }}
                    />
                  )}
                </Td>
              )}
            </StyledTr>
          ))}
        </Tbody>
      </StyledTable>
      {!isLoading && (
        <Box>
          {children && (
            <Flex justifyContent={'flex-end'} mt="1.5rem">
              {children}
            </Flex>
          )}

          {!isLoading && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'row-reverse',
                width: '100%',
              }}
            >
              {buttonDefs?.map((buttonDef, index) => {
                if (buttonDef.visible && !readOnly && !showDeleteMultiple) {
                  return (
                    <Button
                      key={index}
                      style={index >= 1 ? { marginRight: '.75rem' } : {}}
                      isPrimary={true}
                      my="1.5rem"
                      onClick={() => buttonDef.action()}
                      isDisabled={buttonDef.isDisabled}
                      dataTestid={buttonDef.testId}
                    >
                      {buttonDef.label}
                    </Button>
                  );
                }
                return null;
              })}
            </div>
          )}
          {showDeleteMultiple && (
            <StyledDeleteMultipleBtnGroup flexDirection="row">
              <Button
                isPrimary={false}
                onClick={() => setShowDeleteMultiple(false)}
              >
                Cancel
              </Button>
              <ConfirmationDialog
                message={getDialogDeleteMessage(checkedItems)}
                itemName={
                  checkedItems.length > 1
                    ? gridLanguage.pluralItem
                    : gridLanguage.singularItem
                }
                status=""
                onClick={() => onDelete(checkedItems)}
              >
                <Button
                  isPrimary={true}
                  isDisabled={checkedItems.length === 0}
                  transition="width 0.5s ease-in-out"
                  data-testid="confirm-delete-multiple-button"
                  ml="1rem"
                >
                  Delete {gridLanguage.pluralItem}
                </Button>
              </ConfirmationDialog>
            </StyledDeleteMultipleBtnGroup>
          )}
        </Box>
      )}
    </Box>
  );
}
CrudGrid.propTypes = {
  // This your data as an array of objects
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  // The object that represents the row that is currently selected
  selectedRow: PropTypes.any.isRequired,
  // The grid def contains all the information needed to render the grid
  gridDef: PropTypes.shape({
    // GridType: The name of the type of the grid, used as the name for adding and deleting
    gridType: PropTypes.string.isRequired,
    // The sort object that controls how the grid is sorted on load
    defaultSort: PropTypes.shape({
      field: PropTypes.string,
      direction: PropTypes.oneOf(['desc', 'asc']),
    }),
    // To create a unique id for each row, you can pass an array of field names that are then concatenated
    uniqueIdAttributeFields: PropTypes.arrayOf(PropTypes.string),
    // Helper text that is displayed above the grid
    gridLanguage: PropTypes.shape({
      // Helper text that is displayed above the grid
      helperText: PropTypes.string,
      // Singular name of the item in the grid
      singularItem: PropTypes.string,
      // Plural name of the item in the grid
      pluralItem: PropTypes.string,
    }),
    // Function that returns the message to display in the delete confirmation dialog
    getDialogDeleteMessage: PropTypes.func,
    // Function that returns the aria label for each row
    getAriaLabel: PropTypes.func,
    // Boolean that controls if the manage columns button in the toolbar is displayed and allows users to hide/show columns
    manageColumns: PropTypes.bool,
    // Boolean that controls if the filter button is in the tool bar to allow users to filter
    allowFilters: PropTypes.bool,
    // controls is the edit and delete column is shown
    filters: PropTypes.any,
  }).isRequired,
  readOnly: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      // fieldName is the attribute name that represents the data you want in that grid
      fieldName: PropTypes.string,
      // The display text that is shown in the Header for the column
      headerName: PropTypes.string,
      // Boolean that controls if you can sort on this filter, if true you get the icons and the action otherwise false you don't see icons
      sortable: PropTypes.bool,
      // If isNumeric value is aligned to the right as oppose to the left
      isNumeric: PropTypes.bool,
      // Function that would allow the raw data to be translated to more readable values, like boolean to Yes or No, or format currency
      renderCell: PropTypes.func,
      // Boolean that if true would pass a second argument of dropdownOptions back to the callback function renderCell. The dropdownOptions is an object you pass to the grid tied to state incase the options come from an API call
      useOptions: PropTypes.bool,
    }),
  ).isRequired,
  filters: PropTypes.array,
  // function to handle when a row is clicked
  onRowEditClick: PropTypes.func,
  // boolean to control if editRow is disabled
  disableRowClick: PropTypes.bool,
  // function to handle when delete is clicked
  onDelete: PropTypes.func,
  // boolean to control if the delete multiple button is shown
  showDeleteMultiple: PropTypes.bool,
  // boolean to control loading state of the grid
  setShowDeleteMultiple: PropTypes.func,
  // function to handle the setting of the showDeleteMultiple state.
  isLoading: PropTypes.bool,
  // Object with attributes referencing all arrays of options in case a value needs to be mapped.
  dropdownOptions: PropTypes.object,
  multiSelectActionDef: PropTypes.shape({
    buttonLabel: PropTypes.string,
    subActions: PropTypes.any,
  }),
  buttonDefs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.func,
      visible: PropTypes.bool,
      isDisabled: PropTypes.bool,
      testId: PropTypes.string,
    }),
  ),
  onGridModeChange: PropTypes.func,
  children: PropTypes.node,
  // Enabling Audit History is depending on isUndwriter && displayAuditHistory returned as true
  isAuditHistoryEnabled: PropTypes.bool,
};
