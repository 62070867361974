import { ASSETS_TYPES } from './types';
import { API_STATUS } from 'common/constants';
import assetLanguage from 'pages/Assets/language.json';

const initialState = {
  status: null,
  error: null,
  data: {
    assetsInformation: null,
    assetsCalculationInformation: null,
  },
  deleteStatus: null,
  isDocumentProvided: false,
  assetType: null,
};

export const assetsSummary = (state = initialState, { type, payload }) => {
  switch (type) {
    case ASSETS_TYPES.DELETE_ASSETS_STARTED:
      return {
        ...state,
        deleteStatus: API_STATUS.UPDATING,
      };
    case ASSETS_TYPES.DELETE_ASSETS_SUCCESS:
      return {
        ...state,
        deleteStatus: API_STATUS.DONE,
        error: null,
      };
    case ASSETS_TYPES.DELETE_ASSETS_ERROR:
      return {
        ...state,
        error: assetLanguage.DELETE_UPDATING_ERROR,
        deleteStatus: API_STATUS.ERROR,
      };
    case ASSETS_TYPES.RECONCILE_ASSETS_STARTED:
      return {
        ...state,
        reconcileStatus: API_STATUS.UPDATING,
      };
    case ASSETS_TYPES.RECONCILE_ASSETS_SUCCESS:
      return {
        ...state,
        reconcileStatus: API_STATUS.DONE,
        error: null,
      };
    case ASSETS_TYPES.GET_ASSET_RECONCILIATION_INFORMATION_STARTED:
      return {
        ...state,
        status: API_STATUS.GETTING,
      };
    case ASSETS_TYPES.GET_ASSET_RECONCILIATION_INFORMATION_SUCCESS:
      return {
        ...state,
        status: API_STATUS.DONE,
        data: {
          ...state.data,
          assetReconciliationInformation: payload,
        },
      };
    case ASSETS_TYPES.GET_ASSET_RECONCILIATION_INFORMATION_ERROR:
      return {
        ...state,
        error: assetLanguage.GET_ASSETS_RECONCILIATION_INFORMATION_ERROR,
        status: API_STATUS.ERROR,
      };

    case ASSETS_TYPES.SET_IS_DOCUMENT_PROVIDED:
      return {
        ...state,
        isDocumentProvided: payload,
      };
    case ASSETS_TYPES.SET_ASSET_CALCULATION_INFORMATION:
      return {
        ...state,
        data: {
          ...state.data,
          assetsCalculationInformation: payload,
        },
      };
    case ASSETS_TYPES.SET_ASSET_TYPE:
      return {
        ...state,
        assetType: payload,
      };
    default:
      return state;
  }
};

export default assetsSummary;
