import CalculationHistoryHOC from 'pages/IncomeCalculator/CalculationHistory/index.js';
import { UnnamedWidget } from 'lib-node-amb-renderer';
import PropTypes from 'prop-types';

export class CalculationHistoryClass extends UnnamedWidget {
  constructor(props) {
    super(props);
    this.sx = props.sx;
  }

  execute(data, props) {
    return super.execute(data, props);
  }
}

const defaultSx = {
  marginTop: '.5rem',
};

const CalculationHistory = ({ sx = {} }) => {
  return <CalculationHistoryHOC sx={{ ...defaultSx, ...sx }} />;
};

CalculationHistory.propTypes = {
  sx: PropTypes.object,
};

export default CalculationHistory;
