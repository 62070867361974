import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpArrowDown } from '@fortawesome/pro-regular-svg-icons';
import theme from 'theme/';
export const SortIcon = ({ fieldName, sortable, gridSort, isReadOnlyUrl }) => {
  if (typeof sortable !== 'boolean' || sortable) {
    if (fieldName === gridSort.field) {
      return (
        <FontAwesomeIcon
          icon={gridSort.icon}
          data-testid={`sort-icon-${gridSort.direction}`}
          style={{
            color: isReadOnlyUrl ? theme.colors?.black : theme.colors?.white,
          }}
        />
      );
    } else {
      return (
        <FontAwesomeIcon
          icon={faArrowUpArrowDown}
          data-testid={`sort-icon`}
          style={{
            color: isReadOnlyUrl ? theme.colors?.black : theme.colors?.white,
          }}
        />
      );
    }
  }
  return <div data-testid="no-sort-icon"></div>;
};
SortIcon.propTypes = {
  fieldName: PropTypes.string,
  sortable: PropTypes.bool,
  gridSort: PropTypes.shape({
    field: PropTypes.string,
    direction: PropTypes.string,
    icon: PropTypes.object,
  }),
  isReadOnlyUrl: PropTypes.bool,
};
