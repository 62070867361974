﻿const CurrencyFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const NumberFormatter = Intl.NumberFormat('en-US');

/**
 * Formats a number to a currency string with the option to return back a number with the $ stripped off.
 * @param number : number The currency amount.
 * @returns {string} The currency string in the format $12,345.67
 */
export function formatCurrency(number) {
  return CurrencyFormatter.format(number);
}

/**
 * Formats a date using a standard format.
 * @param date : string|Number|date A date.
 * @returns {string} The formatted date string.
 */
export function formatDate(date) {
  return new Date(date).toLocaleDateString('en-US');
}

/**
 * Converts a date to the format "2024-10-11T04:00:00.000Z".
 * @param date : string|Number|date A date.
 * @returns {string} The formatted date string.
 */
export function formatDateToISOString(date) {
  return new Date(date).toISOString();
}

/**
 * Converts a date string to the format "yyyy-MM-dd HH:mm:ss.ffff".
 * @param {string} dateString - The input date string.
 * @returns {string} The formatted date string.
 */
export function convertToCustomFormat(dateString) {
  const date = new Date(dateString);

  const pad = (num, size) => String(num).padStart(size, '0');

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1, 2);
  const day = pad(date.getDate(), 2);
  const hours = pad(date.getHours(), 2);
  const minutes = pad(date.getMinutes(), 2);
  const seconds = pad(date.getSeconds(), 2);
  const milliseconds = pad(date.getMilliseconds(), 4);

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
}

/**
 * get month name from  a date using a standard format.
 * @param date : string|Number|date A date.
 * @returns {string} Month name string.
 */
export function formatDateStringToMonthName(date) {
  return new Date(date).toLocaleString('en-us', { month: 'long' });
}

/**
 * Formats a number (non-currency) using a standard format.
 * @param number : string A number.
 * @returns {string} The formatted date string.
 */
export function formatNumber(number) {
  return NumberFormatter.format(number);
}

/**
 * Formats a string (currency consisting of commas and .) into a standard number format.
 * @param str: string .
 * @returns {number} The formatted date string.
 */
export function reverseFormatCurrency(str) {
  return parseFloat(str?.replace(/,/g, ''));
}

/**
 * Formats a string (SSN value consists of digit and -) into a standard SSN value format.
 * @param val: string initial unformatted SSN
 * @returns {string} The formatted SSN string.
 */
export const formatSocialSecurity = (val) => {
  const regex = '^[0-9-]*$';
  const parseNumber = (string) => (string?.match(regex) || []).join('');
  const valueAsNumber = parseNumber(val?.toString());

  let rawSSN = valueAsNumber.split('-').join('');

  if (rawSSN.length >= 9) {
    rawSSN = rawSSN.substring(0, 9);
  }

  let res;

  if (rawSSN.length <= 3) {
    res = rawSSN;
  } else if (rawSSN.length > 3 && rawSSN.length <= 5) {
    res = rawSSN.substring(0, 3) + '-' + rawSSN.substring(3, rawSSN.length);
  } else if (rawSSN.length > 5 && rawSSN.length <= 9) {
    res =
      rawSSN.substring(0, 3) +
      '-' +
      rawSSN.substring(3, 5) +
      '-' +
      rawSSN.substring(5, rawSSN.length);
  }

  return res;
};

/**
 * Formats the input name (string) for the override question.
 * @param str: string The Input Name. (e.g. Variable Hourly Income)
 * @returns {string} The formatted string. (e.g. variable hourly)
 */
export const formatOverrideInput = (str) =>
  str
    .split(' ')
    .filter((str) => !str.includes('Income'))
    .map((str) => str.toLowerCase())
    .join(' ');

const numberAccept = /[\d.]+/g;
const parseNumber = (string) => (string.match(numberAccept) || []).join('');

const formatFloatingPointNumber = (value, maxDigits) => {
  if (value === null) return;
  const valueAsNumber = parseNumber(value.toString());
  const [dollars, cents] = valueAsNumber.split('.');

  // Avoid rounding errors at toLocaleString as when user enters 1.239 and maxDigits=2 we
  // must not to convert it to 1.24, it must stay 1.23
  const scaledTail = cents != null ? cents.slice(0, maxDigits) : '';
  const number = Number.parseFloat(`${dollars}.${scaledTail}`);

  if (Number.isNaN(number)) {
    return '';
  }

  const valueAsCurrency = number.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: maxDigits,
  });

  if (valueAsNumber.includes('.')) {
    const [formattedHead] = valueAsCurrency.split('.');
    return `${formattedHead}.${scaledTail}`;
  }
  return valueAsCurrency;
};

/**
 * Convert a text string to a number. (may not be exhaustive)
 * @param text A string containing a number written in english.
 * @return {number} The requested number (probably).
 */
export const textToNumber = (text) => {
  switch (text.toLowerCase()) {
    case 'one':
      return 1;
    case 'two':
      return 2;
    case 'three':
      return 3;
    default:
      return NaN;
  }
};

/**
 * Formats a string to a currency string.
 * @param string : string The currency amount.
 * @returns {string} The formatted currency string.
 */
export const formatCurrencyValue = (string) =>
  formatFloatingPointNumber(string, 2);

/**
 * formats a string from the datepicker 'mm/dd/yyyy' to DateTime 'yyyy-mm-dd'
 * @param {string} dateString 'mm/dd/yyyy'
 * @returns {string} a string parsed into server DateTime format yyyy-mm-dd
 */
export const formatDateStringToDateTime = (dateString) => {
  const date = new Date(dateString);
  return `${date.getFullYear()}-${appendZero(date.getMonth() + 1)}-${appendZero(
    date.getDate(),
  )}`;
};

const appendZero = (number) => {
  return number < 10 ? `0${number}` : `${number}`;
};

/**
 * formats a string from a hex value '#FFFFFF' to an rgb value 'rgb(255, 255, 255)'
 * @param {string} hexString '#FFFFFF'
 * @returns {string} an rgb value as a string
 */
export const formatHexToRgbString = (hexString) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexString);
  return `rgb(${parseInt(result[1], 16)}, ${parseInt(
    result[2],
    16,
  )}, ${parseInt(result[3], 16)})`;
};

/**
 * Parse the overridden amount type.
 * @param string : e.g. overriddenBaseIncome.
 * @returns {string} e.g. base.
 */
export const parseOverriddenType = (type) =>
  type
    .split(/(?=[A-Z])/)
    .map((w) => w.toLowerCase())
    .filter((w) => !['overridden', 'income'].includes(w))
    .join(' ');

/**
 * Converts a string to a nullable boolean value.
 * @param value {string} The string to convert.
 * @return {null|boolean} The boolean, or null if it is undefined.
 */
export function stringToNullableBoolean(value) {
  switch (value?.toLowerCase()) {
    case 'true':
    case '1':
      return true;
    case 'false':
    case '0':
      return false;
    default:
      return null;
  }
}

/**
 given a date range ( startDays, endDays ) retuns true if  date is in  range 
 */

export function isDateInBetween(date, startDays, endDays) {
  if (!date || !startDays || !endDays) {
    return false;
  }
  const today = new Date();
  const givenDate = new Date(date);
  const differenceInTime = today.getTime() - givenDate.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  return differenceInDays > startDays && differenceInDays < endDays;
}

export const formatEmployerIdentificationNumber = (value) => {
  let result;

  if (value === null || value === undefined) {
    return '';
  }

  let rawEIN = parseNumber(value?.toString());
  let rawLength = rawEIN.length;

  if (rawLength >= 9) {
    result = rawEIN.substring(0, 9);
  }

  if (rawLength <= 2) {
    result = rawEIN;
  }

  if (rawLength > 2) {
    result = rawEIN.substring(0, 2) + '-' + rawEIN.substring(2, rawLength);
  }

  return result;
};

export async function formatApprovalPdf(pdfInstance) {
  let boltApprovalPdfBinary = '';
  const boltApprovalPdfArrayBuffer = await pdfInstance?.blob?.arrayBuffer();
  const boltApprovalPdfBytes = new Uint8Array(boltApprovalPdfArrayBuffer);
  for (let byte of boltApprovalPdfBytes) {
    boltApprovalPdfBinary += String.fromCharCode(byte);
  }

  return btoa(boltApprovalPdfBinary);
}

// Formats a string to remove all white spaces.
export const removeWhiteSpace = (string) => {
  return string.replace(/\s/g, '');
};

//check if object is undefined/null/empty or whitespaces
export const isEmptyOrSpaces = (string) => !string?.trim();

export const formatDateWithTime = (datetime) => {
  return new Date(datetime).toLocaleString();
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export function parseTime(timeStr) {
  const [time, modifier] = timeStr.split(' ');
  let [hours, minutes] = time.split(':').map(Number);

  if (modifier === 'pm' && hours !== 12) {
    hours += 12;
  } else if (modifier === 'am' && hours === 12) {
    hours = 0;
  }

  return { hours, minutes };
}
