import { useSelector } from 'react-redux';
import { RULE_ID } from 'common/constants';
import { IncomeRuleIds } from 'pages/IncomeCalculator/enums';
import globalLanguage from 'common/constants/language.json';
import { NetProceeds } from 'pages/Assets/AssetDepositoryAccounts/enum';

/**
 *  hook to determine full view if the page uses the PDFViewer component
 * @returns {object} isFullPageWithPDFViewer, isTitleCommitmentRuleId, isTitlePackageReviewRuleId,
 */
const useFullPageWithPDFViewer = () => {
  //INFO: redux state bool from AssetDepositoryAccounts GET call - needed to check if asset page will render PDFViewer
  const isDocumentProvided = useSelector(
    ({ assets }) => assets?.isDocumentProvided,
  );
  const ruleId = useSelector(({ home }) => home?.selectedAlert?.ruleId);
  const displayTitle = useSelector(
    ({ home }) => home?.selectedAlert?.displayTitle,
  );
  const assetType = useSelector(({ assets }) => assets?.assetType);

  const {
    WIRE_INSTRUCTIONS,
    TITLE_COMMITMENT,
    CPL,
    SELLER_ACQUISITION,
    VALIDATE_PAYSTUB,
  } = globalLanguage.HEADER_TITLES;

  //INFO: displayTitle is different from displayTitle in GetNavigationDetails call
  const doesContainCalculatePaystubText =
    displayTitle?.includes('Calculate') && displayTitle?.includes('Paystub');

  const isWireRuleId =
    ruleId === RULE_ID.WIRE_INSTRUCTIONS_RULE &&
    displayTitle === WIRE_INSTRUCTIONS; //INFO: WireInstructions component is in React, needed for ruleId check in GlobalLayout & FormTemplate
  const isCPLRuleId =
    ruleId === RULE_ID.CPL_REVIEW_RULE && displayTitle === CPL;
  const isTitleCommitmentRuleId =
    ruleId === RULE_ID.TITLE_COMMITMENT_RULE &&
    displayTitle === TITLE_COMMITMENT;
  const isSellerAcquisitionRuleId =
    ruleId === RULE_ID.SELLER_ACQUISITION_RULE &&
    displayTitle === SELLER_ACQUISITION;
  const isAssetPageWithPDFViewer =
    (ruleId === RULE_ID.ASSET_TYPE_RULE_ID &&
      (isDocumentProvided || assetType === NetProceeds)) ||
    ruleId === RULE_ID.ASSET_BANK_STATEMENT_RULE_ID;
  const isCalculatePaystubRuleId =
    ruleId === IncomeRuleIds.SmartCalculateIncome &&
    doesContainCalculatePaystubText;

  const isValidatePaystubRuleId =
    ruleId === IncomeRuleIds.SmartValidateDocument &&
    displayTitle === VALIDATE_PAYSTUB;
  const isTitlePackageReviewRuleId =
    isCPLRuleId ||
    isWireRuleId ||
    isTitleCommitmentRuleId ||
    isSellerAcquisitionRuleId;
  const isPurchaseAgreementRuleId =
    ruleId === RULE_ID.PURCHASE_AGREEMENT_RULE_ID;
  const isFullPageWithPDFViewer =
    isWireRuleId ||
    isTitlePackageReviewRuleId ||
    isAssetPageWithPDFViewer ||
    isValidatePaystubRuleId ||
    isCalculatePaystubRuleId ||
    isPurchaseAgreementRuleId;

  return {
    isFullPageWithPDFViewer,
    isTitleCommitmentRuleId,
    isTitlePackageReviewRuleId,
  };
};

export default useFullPageWithPDFViewer;
